.download_report_bar {
    display: flex;
    padding-top: 30px;
    gap: 16px;
    box-sizing: border-box;
    align-items: center;
}

.checkbox_label {
    width: fit-content;
    min-width: fit-content;
    height: fit-content;
}