.dialog_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog {
  background-color: #fafafa;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  h1 {
    font-weight: 600;
    margin-bottom: 16px;
    font-size: large;
  }
  & footer {
    display: flex;
    justify-content: space-between;
    .close_button {
      border: 1px solid red;
    }
  }
}

.dialog_content {
  padding: 30px 60px;
  & header {
    font-weight: 600;
  }
}
